import { makeVar } from '@apollo/client';
import storeJS from 'store';

export const appVar = makeVar({
  isAuthenticated: !!storeJS.get('token'),
  language: storeJS.get('i18nextLng') || 'en',
  cookieConsentBasic: storeJS.get('cookieConsentBasic') || false,
  cookieConsentFunctionality:
    storeJS.get('cookieConsentFunctionality') || false,
  cookieConsentPerformance: storeJS.get('cookieConsentPerformance') || false,
  cookieConsentTracking: storeJS.get('cookieConsentTracking') || false,
  dashboardDisplayStyle: storeJS.get('dashboardDisplayStyle') || 'list',
  questionsFromQuizWizard: null,
  isMenuExpanded: storeJS.get('isMenuExpanded') || false,
});

export const editorVar = makeVar({
  status: null,
  questionsSelected: [],
  chapterSelected: null,
  commentSelected: null,
  questionType: null,
  questionEditing: null,
  folderEditing: null,
  chapterEditing: null,
  gameEditing: null,
  collaboratorEditing: null,
  groupEditing: null,
  studentEditing: null,
});

export const expertVar = makeVar({
  display: 'loading',
  questions: [],
  selectedQuestionIndex: 0,
});

export const studyVar = makeVar({
  studySession: null,
  chapters: [],
  chaptersIndex: 0,
  questions: [],
  questionsIndex: 0,
  performanceIndex: null,
  display: 'loading',
  transition: 'next',
  stats: {
    nTotal: 0,
    nCorrect: 0,
    nWrong: 0,
    nUnknown: 0,
    nSkip: 0,
  },
  hasMascotFlag: false,
});

export const playVar = makeVar({
  playSession: null,
  questions: null,
  questionsIndex: 0,
  display: 'loading',
  transition: 'next',
  score: 0,
  isMuted: false,
});

export const testVar = makeVar({
  remainingTime: null,
  testSession: null,
  questions: null,
  questionsIndex: 0,
  display: 'loading',
});

export const testReviewVar = makeVar({
  testSession: null,
  questions: null,
  questionsIndex: 0,
  display: 'loading',
});
