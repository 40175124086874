import { gql, useQuery } from '@apollo/client';

export const QUERY_APP = gql`
  {
    app @client {
      isAuthenticated
      language
      cookieConsentBasic
      cookieConsentFunctionality
      cookieConsentPerformance
      cookieConsentTracking
      dashboardDisplayStyle
      questionsFromQuizWizard
      isMenuExpanded
    }
  }
`;

export function useQueryLocalApp(options) {
  const { loading, data, ...props } = useQuery(QUERY_APP, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_EDITOR = gql`
  {
    editor @client {
      status
      questionsSelected
      chapterSelected
      commentSelected
      questionType
      questionEditing
      folderEditing
      chapterEditing
      groupEditing
      collaboratorEditing
      gameEditing
      studentEditing
    }
  }
`;

export function useQueryLocalEditor(options) {
  const { loading, data, ...props } = useQuery(QUERY_EDITOR, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_EXPERT = gql`
  {
    expert @client {
      display
      questions
      selectedQuestionIndex
    }
  }
`;

export function useQueryLocalExpert(options) {
  const { loading, data, ...props } = useQuery(QUERY_EXPERT, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_PLAY = gql`
  {
    play @client {
      playSession
      questions
      questionsIndex
      display
      transition
      score
      isMuted
    }
  }
`;

export function useQueryLocalPlay(options) {
  const { loading, data, ...props } = useQuery(QUERY_PLAY, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_STUDY = gql`
  {
    study @client {
      studySession
      chapters
      chaptersIndex
      questions
      questionsIndex
      performanceIndex
      display
      transition
      stats
      hasMascotFlag
    }
  }
`;

export function useQueryLocalStudy(options) {
  const { loading, data, ...props } = useQuery(QUERY_STUDY, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_TEST = gql`
  {
    test @client {
      remainingTime
      testSession
      questions
      questionsIndex
      display
    }
  }
`;

export function useQueryLocalTest(options) {
  const { loading, data, ...props } = useQuery(QUERY_TEST, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_TEST_REVIEW = gql`
  {
    testReview @client {
      testSession
      questions
      questionsIndex
      display
    }
  }
`;

export function useQueryLocalTestReview(options) {
  const { loading, data, ...props } = useQuery(QUERY_TEST_REVIEW, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
